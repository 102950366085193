import React, { useEffect, useState } from "react";
import {
  MDBModal,
  MDBRow,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter
} from 'mdbreact'
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { Button, Grid } from '@mui/material'
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';

import Table from "./Table";
import Masks from "../Masks/Masks";
import { useAuthDataContext } from '../../services/auth/AuthContext';
import FornecedoresService from "../../services/fornecedores/fornecedores";
import { GetAllPrincipalComponent } from '../GetAll'

import ReactLoading from 'react-loading';

import './styles.css';

export default function CrudFornecedores() {
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useAuthDataContext()
  const mask = new Masks()
  const fornecedorService = new FornecedoresService()
  const [modal, setModal] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [fornecedores, setFornecedores] = useState([])
  const [fornecedor, setFornecedor] = useState(null)
  const [error, setError] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)
  // Variáveis para fornecedores:
  const [id, setId] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [razaoSocial, setRazaoSocial] = useState('')
  const [nomeFantasia, setNomeFantasia] = useState('')
  const [inscricaoEstadual, setInscricaoEstadual] = useState('')
  const [impostoProdutoInd, setImpostoProdutoInd] = useState('')
  const [percIpi, setPercIpi] = useState('')
  const [comissao, setComissao] = useState('')
  const [prazoEntrega, setPrazoEntrega] = useState('')
  const [observacoes, setObservacoes] = useState('')
  // Variáveis do modelo frete em fornecedor:
  const [freteId, setFreteId] = useState('')
  const [freteNome, setFreteNome] = useState('')
  // const [fretePercentual, setFretePercentual] = useState('')
  // Variáveis do modelo endereco Cadastral em fornecedor:
  const [idEnderecoCad, setIdEnderecoCad] = useState('')
  const [logradouroCad, setLogradouroCad] = useState('')
  const [numeroCad, setNumeroCad] = useState('')
  const [bairroCad, setBairroCad] = useState('')
  const [municipioCad, setMunicipioCad] = useState('')
  const [complementoCad, setComplementoCad] = useState('')
  const [cepCad, setCepCad] = useState('')
  const [ufCad, setUfCad] = useState('')
  // Variáveis do modelo contato principal em fornecedor:
  const [idContatoPrin, setIdContatoPrinc] = useState('')
  const [departamentoPrinc, setDepartamentoPrinc] = useState('')
  const [emailPrinc, setEmailPrinc] = useState('')
  const [telefonePrinc, setTelefonePrinc] = useState('')
  const [celularPrinc, setCelularPrinc] = useState('')
  // Variáveis do modelo contato secundário em fornecedor:
  const [idContatoSec, setIdContatoSec] = useState('')
  const [departamentoSec, setDepartamentoSec] = useState('')
  const [emailSec, setEmailSec] = useState('')
  const [telefoneSec, setTelefoneSec] = useState('')
  const [celularSec, setCelularSec] = useState('')

  // Toggles
  const toggle = () => {
    setModal(!modal)
  }

  const toggleDelete = () => {
    setModalDelete(!modalDelete)
  }

  // Create
  const handleSubmit = () => {
    const fornecedor = {
      cnpj: cnpj,
      razaoSocial: razaoSocial,
      nomeFantasia: nomeFantasia,
      inscricaoEstadual: inscricaoEstadual,
      impostoProdutoInd: impostoProdutoInd,
      comissao: comissao,
      prazoEntrega: prazoEntrega,
      observacoes: observacoes,
      percIpi: percIpi,

      freteNome: freteNome,
      // fretePercentual: fretePercentual,

      logradouroCad: logradouroCad,
      numeroCad: numeroCad,
      bairroCad: bairroCad,
      municipioCad: municipioCad,
      complementoCad: complementoCad,
      cepCad: cepCad,
      ufCad: ufCad,

      departamentoPrinc: departamentoPrinc,
      emailPrinc: emailPrinc,
      telefonePrinc: telefonePrinc,
      celularPrinc: celularPrinc,

      departamentoSec: departamentoSec,
      emailSec: emailSec,
      telefoneSec: telefoneSec,
      celularSec: celularSec
    }
    try {
      fornecedorService.create(fornecedor)
      .then(res => {
        console.log(res)
        alert("Fornecedor criado com sucesso!!")
        toggle()
        GetAllPrincipalComponent(setFornecedores, setError, fornecedorService, setIsLoading)
      })
    } catch(erro) {
      alert("Ocorreu algum erro, verifique e tente novamente!!")
      console.log(erro)
      setError(erro)
    }
  }

  // Update
  const handleUpdate = (fornecedor) => {
    setIsUpdate(true)
    if (fornecedor.imposto_produto_ind=='sim') {
      setImpostoProdutoInd("1")   
      setPercIpi(fornecedor.percentual_ipi)   
    } 
    if (fornecedor.imposto_produto_ind=='nao') {
      setImpostoProdutoInd("2")
      setPercIpi('-')  
    } 

    console.log(fornecedor.prazo_entrega)
    if (fornecedor.prazo_entrega==30) {
      setPrazoEntrega("1")
    }
    if (fornecedor.prazo_entrega==45) {
      setPrazoEntrega("2")
    }
    if (fornecedor.prazo_entrega==60) {
      setPrazoEntrega("3")
    }
    if (fornecedor.prazo_entrega==75) {
      setPrazoEntrega("4")
    }
    if (fornecedor.prazo_entrega==90) {
      setPrazoEntrega("5")
    }
    if (fornecedor.prazo_entrega==120) {
      setPrazoEntrega("6")
    }

    if (fornecedor.frete_total.nome=="cif") {
      setFreteNome("1")
    }
    if (fornecedor.frete_total.nome=="fob") {
      setFreteNome("2")
    }
    setFreteId(fornecedor.frete_total.id)
    // setFretePercentual(fornecedor.frete_total.percentual)

    setId(fornecedor.id)
    setCnpj(fornecedor.cnpj)
    setRazaoSocial(fornecedor.razao_social)
    setNomeFantasia(fornecedor.nome_fantasia)
    setInscricaoEstadual(fornecedor.inscricao_estadual)
    setComissao(fornecedor.comissao)
    setObservacoes(fornecedor.observacoes)

    setIdEnderecoCad(fornecedor.endereco_cadastral.id)
    setLogradouroCad(fornecedor.endereco_cadastral.logradouro)
    setNumeroCad(fornecedor.endereco_cadastral.numero)
    setBairroCad(fornecedor.endereco_cadastral.bairro)
    setMunicipioCad(fornecedor.endereco_cadastral.municipio)
    setComplementoCad(fornecedor.endereco_cadastral.complemento)
    setCepCad(fornecedor.endereco_cadastral.cep)
    setUfCad(fornecedor.endereco_cadastral.uf)

    setIdContatoPrinc(fornecedor.contato_principal.id)
    setDepartamentoPrinc(fornecedor.contato_principal.departamento)
    setEmailPrinc(fornecedor.contato_principal.email)
    setTelefonePrinc(fornecedor.contato_principal.telefone)
    setCelularPrinc(fornecedor.contato_principal.celular)

    if (fornecedor.contato_secundario !== null) {
      setIdContatoSec(fornecedor.contato_secundario.id)
      setDepartamentoSec(fornecedor.contato_secundario.departamento)
      setEmailSec(fornecedor.contato_secundario.email)
      setTelefoneSec(fornecedor.contato_secundario.telefone)
      setCelularSec(fornecedor.contato_secundario.celular)
    } else  {
      setIdContatoSec("")
      setDepartamentoSec("")
      setEmailSec("")
      setTelefoneSec("")
      setCelularSec("")
    }
    
    toggle()
  }

  // Verificando o update:
  const confirmUpdate = () => {
    const fornecedor = {
      id: id,
      cnpj: cnpj,
      razaoSocial: razaoSocial,
      nomeFantasia: nomeFantasia,
      inscricaoEstadual: inscricaoEstadual,
      impostoProdutoInd: impostoProdutoInd,
      comissao: comissao,
      prazoEntrega: prazoEntrega,
      observacoes: observacoes,
      percIpi: percIpi,

      freteId: freteId,
      freteNome: freteNome,
      // fretePercentual: fretePercentual,

      idEnderecoCad: idEnderecoCad,
      logradouroCad: logradouroCad,
      numeroCad: numeroCad,
      bairroCad: bairroCad,
      municipioCad: municipioCad,
      complementoCad: complementoCad,
      cepCad: cepCad,
      ufCad: ufCad,

      idContatoPrin: idContatoPrin,
      departamentoPrinc: departamentoPrinc,
      emailPrinc: emailPrinc,
      telefonePrinc: telefonePrinc,
      celularPrinc: celularPrinc,

      idContatoSec: idContatoSec,
      departamentoSec: departamentoSec,
      emailSec: emailSec,
      telefoneSec: telefoneSec,
      celularSec: celularSec
    }

    try {
      fornecedorService.update(fornecedor.id, fornecedor)
      .then(res => {
        if (res.status === 200) {
          alert("Fornecedor atualizado com sucesso!!")
          toggle()
          GetAllPrincipalComponent(setFornecedores, setError, fornecedorService, setIsLoading)
        }
      })
    } catch(erro) {
      console.log(erro)
      alert("Ocorreu algum erro, verifique e tente novamente!!")
    }
  }

  // Delete 
  const handleDelete = (idFornecedor) => {
    fornecedorService.delete(idFornecedor)
    .then(res => {
      if (res.status === 200) {
        alert("Fornecedor apagado com sucesso!!")
        toggleDelete()        
        GetAllPrincipalComponent(setFornecedores, setError, fornecedorService, setIsLoading)

      }
    })
  }

  const checkValuesFornecedor = () => {

    if (
      razaoSocial=="" || 
      cnpj=="" ||
      inscricaoEstadual=="" ||
      impostoProdutoInd=="" ||
      impostoProdutoInd=="Selecione se possui IPI" ||
      prazoEntrega=="" ||
      prazoEntrega=="Selecione Prazo de Entrega" ||
      freteNome=="" ||
      // fretePercentual=="" ||
      logradouroCad=="" ||
      numeroCad==""  ||
      bairroCad=="" ||
      municipioCad=="" ||
      cepCad=="" ||
      ufCad=="" ||
      emailPrinc=="" ||
      departamentoPrinc==""
    ) {
      return true
    } else {
      if (
        cnpj.length < 18 ||
        inscricaoEstadual.length < 15 ||
        cepCad.length < 10 ||
        ufCad.length != 2 ||
        cepCad.length < 10 ||
        ufCad.length != 2 
      ) {
        return true
      } else {
        return false
      }
    }
  } 


  const clearState = () => {

    setCnpj("")
    setRazaoSocial("")
    setNomeFantasia("")
    setInscricaoEstadual("")
    setImpostoProdutoInd("")
    setPercIpi("")
    setComissao("")
    setPrazoEntrega("")
    setObservacoes("")

    setFreteNome("")
    // setFretePercentual("")

    setLogradouroCad("")
    setNumeroCad("")
    setBairroCad("")
    setMunicipioCad("")
    setComplementoCad("")
    setCepCad("")
    setUfCad("")

    setDepartamentoPrinc("")
    setEmailPrinc("")
    setTelefonePrinc("")
    setCelularPrinc("")

    setDepartamentoSec("")
    setEmailSec("")
    setTelefoneSec("")
    setCelularSec("")
    
  }
  useEffect(() => {
    GetAllPrincipalComponent(setFornecedores, setError, fornecedorService, setIsLoading)
  }, [])

  return(
    <div>
      {/* Container de inserção de informação (Create/Update/Read Individual)*/}
      <MDBModal 
        isOpen={modal} 
        toggle={toggle} 
        size="lg"
        disableBackdrop
      >
      
        <MDBRow center>
          {isUpdate ? (
            <MDBModalHeader className="modal-header">Informações sobre o fornecedor</MDBModalHeader>
          ) : (
            <MDBModalHeader className="modal-header">Cadastre novo fornecedor</MDBModalHeader>
          )}
        </MDBRow>

        <MDBModalBody>
        <div className="info-form">
          <GridContainer >
            <GridItem xs={12} sm={12} md={4}>
              <p className="p-form">Fornecedor</p>
            </GridItem>
          </GridContainer>
          
          <GridContainer>
            <GridItem xs={6} sm={12} md={6}>
              <MDBInput
                validate
                required
                label="Razão Social *"
                hint={razaoSocial}
                type="text"
                value={razaoSocial}
                onChange={e => setRazaoSocial(mask.charAndText(e.target.value))}
              />
            </GridItem>

            <GridItem className="grid-item" xs={12} sm={12} md={3}>
              <MDBInput
                validate
                required
                label="CNPJ *"
                hint={cnpj}
                type="text"
                value={cnpj}
                onChange={e => setCnpj(mask.cnpj(e.target.value))}
              />
            </GridItem>
            

              <GridItem xs={12} sm={12} md={3}>
                <MDBInput
                  validate
                  required
                  label="Inscrição Estadual *"
                  hint={inscricaoEstadual}
                  type="text"
                  value={inscricaoEstadual}
                  onChange={e => setInscricaoEstadual(mask.ie(e.target.value))}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
            <GridItem xs={6} sm={12} md={6}>
              <MDBInput
                label="Nome Fantasia"
                hint={nomeFantasia}
                type="text"
                value={nomeFantasia}
                onChange={e => setNomeFantasia(mask.charAndText(e.target.value))}
              />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <MDBInput
                  label="Comissão"
                  hint={comissao}
                  type="number"
                  value={comissao}
                  disabled={user.is_superuser ? (false):(true)}
                  onChange={e => setComissao(mask.formatDescontoAndComissao(e.target.value))}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <InputLabel>
                  Prazo de Entrega *
                </InputLabel>
                <NativeSelect
                  value={prazoEntrega}
                  onChange={e => setPrazoEntrega(e.target.value)}
                >
                  <option> Selecione Prazo de Entrega</option>
                  <option value="1">30</option>
                  <option value="2">45</option>
                  <option value="3">60</option>
                  <option value="4">75</option>
                  <option value="5">90</option>
                  <option value="6">120</option>
                </NativeSelect>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <InputLabel>
                  Frete *
                </InputLabel>
                <NativeSelect
                  value={freteNome}
                  onChange={e => setFreteNome(e.target.value)}
                >
                  <option> Selecione o Frete</option>
                  <option value="1">CIF</option>
                  <option value="2">FOB</option>
                </NativeSelect>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <InputLabel>
                  IPI *
                </InputLabel>
                <NativeSelect
                  value={impostoProdutoInd}
                  onChange={e => {
                    setImpostoProdutoInd(e.target.value)
                    setPercIpi("")
                  }}
                >
                  <option> Selecione se possui IPI</option>
                  <option value="1">Sim</option>
                  <option value="2">Não</option>
                </NativeSelect>
              </GridItem>

              {impostoProdutoInd=='1' ? 
              (
                <GridItem xs={12} sm={12} md={6}>
                  <MDBInput
                    validate
                    required
                    label="Percentual IPI"
                    hint={percIpi}
                    type="text"
                    value={percIpi}
                    onChange={e => setPercIpi(mask.formatDescontoAndComissao(e.target.value))}
                  />
                </GridItem>
              ):(null)}
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <MDBInput
                  validate
                  required
                  label="Observações"
                  hint={observacoes}
                  type="textarea"
                  value={observacoes}
                  onChange={e => setObservacoes(mask.charAndText(e.target.value))}
                />
              </GridItem>
            </GridContainer>
          </div>

          {/* <hr className="line"/> */}
          
          {/* <div className="info-form">
            <GridContainer >
              <GridItem xs={12} sm={12} md={4}>
                <p className="p-form">Frete</p>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <MDBInput 
                validate
                required
                label="Nome *"
                hint={freteNome}
                type="text"
                value={freteNome}
                onChange={e => setFreteNome(mask.charAndText(e.target.value))}              
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <MDBInput 
                validate
                required
                label="Percentual *"
                hint={fretePercentual}
                type="text"
                value={fretePercentual}
                onChange={e => setFretePercentual(e.target.value)}              
                />
              </GridItem>
            </GridContainer>
            
          </div> */}
          {/* <hr className="line"/> */}
          
          <div className="info-form">
            <GridContainer >
              <GridItem xs={12} sm={12} md={4}>
                <p className="p-form">Endereço Cadastral</p>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <MDBInput
                  validate
                  required
                  label="Logradouro *"
                  hint={logradouroCad}
                  type="text"
                  value={logradouroCad}
                  onChange={e => setLogradouroCad(mask.charAndText(e.target.value))}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <MDBInput
                  validate
                  required
                  label="Número *"
                  hint={numeroCad}
                  type="text"
                  value={numeroCad}
                  onChange={e => setNumeroCad(e.target.value)}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <MDBInput
                  validate
                  required
                  label="Bairro *"
                  hint={bairroCad}
                  type="text"
                  value={bairroCad}
                  onChange={e => setBairroCad(mask.charAndText(e.target.value))}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              
              <GridItem xs={12} sm={12} md={6}>
                <MDBInput
                  validate
                  required
                  label="Município *"
                  hint={municipioCad}
                  type="text"
                  value={municipioCad}
                  onChange={e => setMunicipioCad(mask.charAndText(e.target.value))}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <MDBInput
                  validate
                  required
                  label="CEP *"
                  hint={cepCad}
                  type="text"
                  name="cep"
                  value={cepCad}
                  onChange={e => setCepCad(mask.cep(e.target.value))}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                  <MDBInput
                    validate
                    required
                    label="UF *"
                    hint={ufCad}
                    type="text"
                    name="uf"
                    value={ufCad}
                    onChange={e => setUfCad(mask.uf(e.target.value))}
                  />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <MDBInput
                  label="Complemento"
                  hint={complementoCad}
                  type="textarea"
                  value={complementoCad}
                  onChange={e => setComplementoCad(mask.charAndText(e.target.value))}
                />
              </GridItem>
            </GridContainer>
          </div>

          {/* <hr className="line"/> */}
          
          <div className="info-form">
            <GridContainer >
              <GridItem xs={12} sm={12} md={4}>
                <p className="p-form">Contato Principal</p>
              </GridItem>
            </GridContainer>

            <GridContainer>
              
              <GridItem xs={12} sm={12} md={5}>
                <MDBInput
                  validate
                  required
                  label="E-mail *"
                  hint={emailPrinc}
                  type="text"
                  value={emailPrinc}
                  onChange={e => setEmailPrinc(mask.email(e.target.value))}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <MDBInput
                  label="Telefone"
                  hint={telefonePrinc}
                  type="text"
                  name="telefone"
                  value={telefonePrinc}
                  onChange={e => setTelefonePrinc(mask.telefone(e.target.value))}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <MDBInput
                  label="Celular"
                  hint={celularPrinc}
                  type="text"
                  name="celular"
                  value={celularPrinc}
                  onChange={e => setCelularPrinc(mask.celular(e.target.value))}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <MDBInput
                  label="Departamento *"
                  hint={departamentoPrinc}
                  type="text"
                  value={departamentoPrinc}
                  onChange={e => setDepartamentoPrinc(mask.charAndText(e.target.value))}
                />
              </GridItem>
            </GridContainer>
          </div>
          
          {/*<hr className="line"/> */}
          
          <div className="info-form">
            <GridContainer >
              <GridItem xs={12} sm={12} md={4}>
                <p className="p-form">Contato Secundário</p>
              </GridItem>
            </GridContainer>

            <GridContainer>
          
              <GridItem xs={12} sm={12} md={5}>
                <MDBInput
                  label="E-mail"
                  hint={emailSec}
                  type="text"
                  value={emailSec}
                  onChange={e => setEmailSec(mask.email(e.target.value))}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <MDBInput
                  label="Telefone"
                  hint={telefoneSec}
                  type="text"
                  name="telefone"
                  value={telefoneSec}
                  onChange={e => setTelefoneSec(mask.telefone(e.target.value))}
                />
              </GridItem>
              
              <GridItem xs={12} sm={12} md={2}>
                <MDBInput
                  label="Celular"
                  hint={celularSec}
                  type="text"
                  value={celularSec}
                  onChange={e => setCelularSec(mask.celular(e.target.value))}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <MDBInput
                  label="Departamento"
                  hint={departamentoSec}
                  type="text"
                  value={departamentoSec}
                  onChange={e => setDepartamentoSec(mask.charAndText(e.target.value))}
                />
              </GridItem>
            </GridContainer>
          </div>
        </MDBModalBody>

        <MDBRow end style={{marginRight: '0px'}}>
          <MDBModalFooter>
            <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggle}>Cancelar</Button>
          </MDBModalFooter>

          <MDBModalFooter>
            {isUpdate ? (
              <Button disabled={checkValuesFornecedor()} variant="contained" onClick={confirmUpdate}>
                Atualizar Fornecedor
              </Button>
            ) : (
              <Button disabled={checkValuesFornecedor()} variant="contained" onClick={handleSubmit}>
                Registrar Fornecedor
              </Button>
            )}
          </MDBModalFooter>
        </MDBRow>
      </MDBModal>
      
      {/* Modal de veificação do delete */}
      {modalDelete? (
        <MDBModal 
          isOpen={modalDelete}
          toggle={toggleDelete}
          disableBackdrop
        >
          <MDBRow center>
            <MDBModalHeader>
              <span className="title-span">
                ATENÇÃO!
              </span>
            </MDBModalHeader>
          </MDBRow>

          <MDBModalBody>
            <MDBRow center>
              <span style={{color: 'black', fontWeight: 'bolder'}}>
                Você deseja excluir esse fornecedor
              </span>
            </MDBRow>
            <MDBRow className="mt-4" center>
              <span>
                CNPJ do Fornecedor: {fornecedor.cnpj}
              </span>
            </MDBRow>

            <MDBRow className="mt-4" center>
                <span>
                  Razão Social do Fornecedor: {fornecedor.razao_social}
                </span>
              </MDBRow>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleDelete}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'red'}} onClick={() => {handleDelete(fornecedor.id)}}>Apagar</Button>
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>
      ) : (null)}

      {/* Table Inicial */}
      {isLoading ? (
        <MDBRow center>
          <ReactLoading  type="bars" color="black" height={400} width={150}/>   
        </MDBRow>
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader className="header-table" color="danger">
                <h4 className="title-table">Lista de Fornecedores</h4>
                <p className="p">
                    Adicione e edite os fornecedores.
                </p>
              </CardHeader>
              <CardBody>
                <Table
                modelos={fornecedores}
                handleUpdate={handleUpdate}
                toggleDelete={toggleDelete}
                setModelo={setFornecedor}
                />

                <Button 
                  variant="contained"
                  style={{marginTop: '-80px'}}
                  onClick={() => {
                  setIsUpdate(false)
                  clearState()
                  toggle()
                }}>
                  Adicionar Fornecedor
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
       
  )
}