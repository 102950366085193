import React, { useState } from 'react';
import { 
    MDBModal,
    MDBRow,
    MDBModalBody,
    MDBModalHeader,
    MDBInput,
    MDBModalFooter
} from 'mdbreact';
import { AiOutlineFileSearch } from "react-icons/ai";
import NativeSelect from '@mui/material/NativeSelect';
import { Button } from '@mui/material'
import GridItem from '../../Grid/GridItem';
import GridContainer from '../../Grid/GridContainer';

export default function ModalFilterClientByField(props) {
    // Variáveis para comunicação com cliente:
    const { clienteService, setClientes, setIsLoading, setIsViewTable } = props

    // Variáveis de controle do Modal
    const [modal, setModal] = useState(false);
    const toggle = () => { 
        setModal(!modal) 
    }
    // Variáveis para filtro
    const [nameField, setNameField] = useState('')
    const [valueField, setValuefield] = useState('')

    // Valida os campos antes de permitir o filtro
    const checkValuesFilterCliente = () => {
        if (
            nameField == "" ||
            nameField == "Selecione o Campo para Pesquisa" ||
            valueField == ""
        ) {
            return true
        } else {
            return false
        }
    }

    // Realiza o filtro por pedidos na api
    const handleSubmit = () => {
        setIsLoading(true)
        const fieldToFilter = {
            nameField: nameField,
            valueField: valueField
        }
        try {
            console.log(clienteService.getByField)
            clienteService.getByField(fieldToFilter)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data)
                    setClientes(res.data)
                    toggle()
                    setIsLoading(false)
                    setIsViewTable(true)
                }
            })
        } catch(error) {
            alert("Ocorreu algum erro: "+error+", verifique e tente novamente!!")
        }
    }
    // Limpa os campos
    const clearState = () => {
        setValuefield("")
        setNameField("")
    }


    return (
        <>
            {/* Modal de Filtro */}
            <MDBModal
                isOpen={modal}
                toggle={toggle}
                size='lg'
                disableBackdrop
            >
                <MDBRow center>
                    <MDBModalHeader style={{color: 'black'}} className="modal-header">Dados para filtrar Cliente</MDBModalHeader>
                </MDBRow>

                <MDBModalBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <NativeSelect
                                value={nameField}
                                onChange={e => {
                                    setNameField(e.target.value)
                                    setValuefield("")
                                }}
                                >
                                <option>Selecione o Campo para Pesquisa</option>
                                <option value="1">Razão Social</option>
                                <option value="2">CNPJ</option>
                                <option value="3">Nome Fantasia</option>
                            </NativeSelect>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <MDBInput 
                                validate
                                required
                                label="Valor do campo de pesquisa (ex. OC 123)"
                                style={{marginTop: '-24px'}}
                                type="text"  
                                hint={valueField}
                                value={valueField}
                                onChange={input => setValuefield(input.target.value)}
                            />
                        </GridItem>
                    </GridContainer>
                </MDBModalBody>

                <MDBRow end style={{marginRight: '0px'}}>
                    <MDBModalFooter>
                        <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggle}>Cancelar</Button>
                    </MDBModalFooter>

                    <MDBModalFooter>
                        <Button disabled={checkValuesFilterCliente()} variant="contained" onClick={handleSubmit} >
                            Filtrar Cliente
                        </Button>
                    </MDBModalFooter>
                </MDBRow>
            </MDBModal>

            
            {/* Botão para filtro */}
            <Button
                style={{ backgroundColor: '#F6EDD2', color: '#B19B80', fontSize: '12px', height: '55px'}}
                variant="contained"
                onClick={() => {
                    toggle()
                    clearState()
                }}

            >
                Filtrar Cliente
                <AiOutlineFileSearch 
                    style={{ height: '40px', width: '40px', paddingLeft: '20px'}}
                />
              </Button>
        </>
    )
}