import React, { useEffect, useState } from 'react';
import { 
  MDBDataTableV5, 
  MDBIcon, 
  MDBModal,
  MDBRow,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBCol,
  MDBContainer,
  MDBModalFooter,
} from 'mdbreact';
import { Button } from '@mui/material';

import { HiMail } from 'react-icons/hi';

import getFormatData from './GetData';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Masks from "../Masks/Masks";

import { useAuthDataContext } from '../../services/auth/AuthContext';

import { getStatus } from '../Formatter/pedidos'; 
import {getConsultorInfo} from '../Formatter/consultores';

export default function Table(props) {

  // Definindo modelo que virá do component Read de cada modelo:
  const { modelos, handleUpdate, toggleDelete, setModelo, setPedidos, pedidosService, getPedidos, isLoadingPedidos, setStatusPedido } = props;

  const { user, userGroup } = useAuthDataContext()
 
  // Definindo linhas e colunas para a tabela:  
  const [columns, setColumns] = useState('')
  const [rows, setRows] = useState('')
  const [error, setError] = useState('')

  // Definindo map para se obter as colunas referentes a tabela:
  const getColumns = () => {
    let dataHeader = []

    if (userGroup == 'Orcamento') {
      dataHeader = 
      [ {
        label: 'Nº do Orçamento',
        field: 'numeroPedido',
        sort: 'desc',
        width: 200,
      },
      {
        label: 'Data Orçamento',
        field: 'dataPedido',
        width: 150,
      },
      {
        label: 'OC ',
        field: 'ocCliente',
        width: 150,
      },
      {
        label: 'Status do Orçamento',
        field: 'statusPedido',
        width: 150,
      },
      {
        label: 'Consultor',
        field: 'consultor',
        width: 200,
      },
      {
        label: 'Cliente',
        field: 'cliente',
        width: 200,
      },
      {
        label: 'Fornecedor',
        field: 'fornecedor',
        width: 200,
      },
      {
        label: 'Ações',
        field: 'acoes',
        width: 200,
      }
     
    ]
    } else {
      dataHeader = 
      [ {
        label: 'Nº do Pedido',
        field: 'numeroPedido',
        sort: 'desc',
        width: 200,
      },
      {
        label: 'Data Pedido',
        field: 'dataPedido',
        width: 150,
      },
      {
        label: 'OC ',
        field: 'ocCliente',
        width: 150,
      },
      {
        label: 'Status do Pedido',
        field: 'statusPedido',
        width: 150,
      },
      {
        label: 'Consultor',
        field: 'consultor',
        width: 200,
      },
      {
        label: 'Cliente',
        field: 'cliente',
        width: 200,
      },
      {
        label: 'Fornecedor',
        field: 'fornecedor',
        width: 200,
      },
      {
        label: 'Ações',
        field: 'acoes',
        width: 200,
      }
     
    ]
    }
    
     
    setColumns(dataHeader)     
  }    
    
  // Definindo map para se obter as linhas referentes a tabela:
  const getRows = () => {

    let dataBody = modelos.map((modelo, key)=>{  
      console.log(modelo)
      if (user.is_superuser){
        if (modelo.pedido.status===1) {
            return {
                dataPedido: getFormatData(modelo.pedido.pedido.data_pedido),
                statusPedido: getStatus(modelo.pedido.pedido.status),          
                numeroPedido: parseInt(modelo.pedido.pedido.id),
                ocCliente: modelo.pedido.pedido.oc_cliente,
                consultor: getConsultorInfo(modelo.consultores),
                cliente: modelo.pedido.pedido.cliente.razao_social,
                fornecedor: modelo.pedido.pedido.fornecedor.razao_social,
                acoes:
                  <div key={key}>      
                    <div className="acoes">
                      <MDBIcon onClick={() =>{
                        handleUpdate(modelo)
                        setStatusPedido(modelo.pedido.status)
                      }}
                      icon="pen"/> 
                    </div> 
      
                    <div className="acoes">
                      <MDBIcon onClick={() => {
                        toggleDelete() 
                        setModelo(modelo)
                      }} 
                      icon="trash"/>
                    </div>     
                  </div>
        
              }
            } else {
              return {
      
                dataPedido: getFormatData(modelo.pedido.data_pedido),
                statusPedido: getStatus(modelo.pedido.status),          
                numeroPedido: parseInt(modelo.pedido.id),
                ocCliente: modelo.pedido.oc_cliente,
                consultor: getConsultorInfo(modelo.consultores),
                cliente: modelo.pedido.cliente.razao_social,
                fornecedor: modelo.pedido.fornecedor.razao_social,
                acoes:
                  <div key={key}>
                    <div className="acoes">
                      <HiMail 
                        onClick={() => {
                          getAnexosByPedido(modelo.pedido.id)
                          setAddEmail([])
                          setEmailList([])
                          setIsPriority(false)
                          // setFilename(nameDocument)
                          setItem(modelo)
                          toggleSendEmail()
                        }}
                      />
                    </div> 
      
                    <div className="acoes">
                      <MDBIcon onClick={() => {
                        handleUpdate(modelo)
                        setStatusPedido(modelo.pedido.status)
                      }} 
                      icon="pen"/> 
                    </div> 
      
                    <div className="acoes">
                      <MDBIcon onClick={() => {
                        toggleDelete() 
                        setModelo(modelo)
                      }} 
                      icon="trash"/>
                    </div>     
                  </div>
        
              }
            }
        } else if (userGroup=='Orcamento') {
          return {
                dataPedido: getFormatData(modelo.pedido.data_pedido),
                statusPedido: getStatus(modelo.pedido.status),          
                numeroPedido: parseInt(modelo.pedido.id),
                ocCliente: modelo.pedido.oc_cliente,
                consultor: getConsultorInfo(modelo.consultores),
                cliente: modelo.pedido.cliente.razao_social,
                fornecedor: modelo.pedido.fornecedor.razao_social,
                acoes:
                  <div key={key}>      
                    <div className="acoes">
                      <MDBIcon onClick={() =>{
                        handleUpdate(modelo)
                        setStatusPedido(modelo.pedido.status)
                      }}
                      icon="pen"/> 
                    </div>     
                  </div>
        
              }
        }
         else {
        return {

          dataPedido: getFormatData(modelo.pedido.data_pedido),
          statusPedido: getStatus(modelo.pedido.status),          
          numeroPedido: parseInt(modelo.pedido.id),
          ocCliente: modelo.pedido.oc_cliente,
          consultor: getConsultorInfo(modelo.consultores),
          cliente: modelo.pedido.cliente.razao_social,
          fornecedor: modelo.pedido.fornecedor.razao_social,
          acoes:
            <div key={key}>
              <div className="acoes">
                <HiMail 
                  onClick={() => {
                    getAnexosByPedido(modelo.pedido.id)
                    setAddEmail([])
                    setEmailList([])
                    // setFilename(nameDocument)
                    setItem(modelo)
                    toggleSendEmail()
                  }}
                />
              </div> 
              <div className="acoes">
                <MDBIcon onClick={() =>
                {handleUpdate(modelo)}} 
                icon="pen"/> 
              </div> 

              <div className="acoes">
                <MDBIcon onClick={() => {
                  toggleDelete() 
                  setModelo(modelo)
                }} 
                icon="trash"/>
              </div>     
            </div>
  
        }
      }
      
      
    })
    setRows(dataBody)
    }
  
  // Definindo useEffect para se obter as informações referentes a tabela,
  //    sejam linhas ou colunas, e que tem como dependência o modelo referenciado
  //    em props;
  useEffect(() => {
    getColumns();
    getRows();        
  }, [modelos]);



  const mask = new Masks()
  
  //////// Emails ////////
  const [isPriority, setIsPriority] = useState(false)
  // SendEmail:
  const [modalSendEmail, setModalSendEmail] = useState(false)
  const [modalDeleteEmail, setModalDeleteEmail] = useState(false)
  const toggleSendEmail = () => {
    setModalSendEmail(!modalSendEmail)
  }
  const toggleDeleteEmail = () => {
    setModalDeleteEmail(!modalDeleteEmail)
  }
  
  // Emails com copia
  const [emailForDel, setEmailForDel] = useState("")
  const [email, setEmail] = useState("")
  const [emailList, setEmailList] = useState([])
  const [addEmail, setAddEmail] = useState([])
  // Add item a lista
  const handleAddEmail = () => {
    addEmail.push(email)
    return addEmail
  }
  // Remover item da lista:
  const handleDelEmail = (email) =>{
    emailList.splice(emailList.indexOf(email), 1)
    toggleDeleteEmail()
    toggleSendEmail()
  }

  
  const sendEmail = () => {
    // const name = "Pedido "+iteconm.n_pedido+" - "+item.data_pedido+".pdf"
    const pedido = item.pedido

    const receiver = pedido.fornecedor.contato_principal.email
    const oc = "(OC "+pedido.oc_cliente+")"
    let subject = pedido.fornecedor.razao_social+"PD "+pedido.n_pedido+" (OC "+pedido.oc_cliente+") - "+pedido.cliente.razao_social
    if (isPriority)
      subject = subject + ' (PRIORIDADE)'
    var emailListFormat = []
    for (let i = 0; i < emailList.length; i++) {
      emailListFormat.push(emailList[i])
    }

    let form_data = new FormData()

    form_data.append('id', pedido.n_pedido)
    form_data.append('receiver', receiver)
    form_data.append('subject', subject)
    form_data.append('emailsComCopia', emailListFormat)
    form_data.append('OC', oc)
    // form_data.append('filenamePedido', name)
    // form_data.append('urlPedido', blob)
    
    if (pedido.fornecedor.contato_secundario != null){
      form_data.append('receiversSec', pedido.fornecedor.contato_secundario.email)
    }

    try {
        pedidosService.senderEmail(pedido.id, form_data)
        .then(res => {
          if (res.status === 200) {
            alert("Email enviado com sucesso!!") 
            toggleSendEmail()
            setPedidos([])
            isLoadingPedidos(true)
            getPedidos()
          }
        })
    } catch(error) {
      alert("Ocorreu algum erro, verifique e tente novamente!!")
    }      
  }

  // Listagens dos anecos
  const [anexos, setAnexos] = useState("")
  const getAnexosByPedido = (idPedido) => {
    pedidosService.getAnexosByPedido(idPedido)
    .then(response => {
      if (response.status === 200) {
        setAnexos(response.data)
      } else {
        setError('Erro aos listar anexos!!')
      }
    })
    .catch(error => {
      setError(error)
    }) 
  }

  const [item, setItem] = useState('')

  // Retornando tabela  
  return (
    <>
      {/* Modal para enviar email */}
      <MDBModal
        size='lg'
        isOpen={modalSendEmail}
        toggle={toggleSendEmail}
        disableBackdrop
      >
        <MDBRow center>
          <MDBModalHeader className="modal-header">Envio de Email - Pedido {item.n_pedido} </MDBModalHeader>
        </MDBRow>

        <MDBModalBody>             
          <div className="info-form">
            <GridContainer >
             <GridItem xs={12} sm={12} md={9}>
               <p className="p-form">Emails</p>
               <span style={{marginTop: "-15px", marginLeft: '5px', overflow: "auto", display: "flex", fontSize: "12px",
                color: "rgb(255, 162, 26)"}}
                >
                ATENÇÃO: Outros e-mails sem considerar o padrão do fornecedor!!</span>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <label style={{marginRight: '15px', marginTop: '30px'}}>
                  É prioridade?
                </label>
                <input type="checkbox"
                  checked={isPriority}
                  onChange={() => {
                    setIsPriority(!isPriority)}
                  }
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem className="grid-item" xs={12} sm={12} md={10}>
                <MDBInput 
                  label="Digite o Email" 
                  type="email"  
                  name='email' 
                  value={email}
                  onChange={(e) => setEmail(mask.email(e.target.value))}
                />    
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={1}>
                <Button 
                  className='btn-email'
                  variant="contained" 
                  size="sm" 
                  style={{backgroundColor: 'green', margin: '20px', marginBottom: '-45px'}} 
                  disabled={email==='' ? true : false}
                  onClick={() => {
                    setEmailList(handleAddEmail())
                    setEmail("")
                  }}
                  >
                  <MDBIcon icon="plus"/>
                </Button> 
              </GridItem>   
            </GridContainer>  
            {emailList.length > 0 ? (
              <>
                <GridContainer style={{marginTop: '15px'}} >
                  <GridItem xs={12} sm={12} md={12}>
                  <p className="p-form">Emails Adicionados</p>
                  </GridItem>
                </GridContainer>
                <MDBContainer style={{marginTop: '-30px'}}>
                    {emailList.map((email, idx) => (  
                      <ul>       
                        <li>                  
                        <MDBRow  key={idx} style={{marginTop: '20px', marginLeft: '7px'}}>
                          <MDBCol md={10}>
                            <div className="row">
                              <span>{email}</span>
                            </div>
                          </MDBCol>
                          <MDBCol md={2}>
                            <Button 
                              size="sm" 
                              variant="contained" 
                              style={{backgroundColor: 'orangered'}}
                              onClick={() =>  {
                                toggleSendEmail()
                                setEmailForDel(email)
                                toggleDeleteEmail()  
                              }}
                            >
                              <MDBIcon icon="trash" />
                            </Button>                      
                          </MDBCol> 
                        </MDBRow>
                        </li>
                      </ul>       
                        
                  ))}
                </MDBContainer>
              </>
            ) : (null)}
          </div>
          
          <div className="info-form">
            <GridContainer >
             <GridItem xs={12} sm={12} md={12}>
               <p className="p-form">Anexos</p>
               <span style={{marginTop: "-15px", marginLeft: '5px', overflow: "auto", display: "flex", fontSize: "12px",
                color: "rgb(255, 162, 26)"}}
                >
                ATENÇÃO: Outros anexos sem considerar o PDF do Pedido!</span>
              </GridItem>
            </GridContainer>
            
            <MDBContainer>
              {anexos.length > 0 ? (
                <>
                {anexos.map((anexo, idx) => (
                  <ul>       
                    <li>
                    <GridItem key={idx} xs={12} sm={12} md={12}>
                      <a className='link-anexo' href={anexo.anexo} target='_blank'>
                        {anexo.filename}
                      </a> 
                    </GridItem>
                    </li>
                  </ul>
                ))}             
                </>
              ):(null)}
            </MDBContainer>            
          </div>

        </MDBModalBody>

        <MDBRow end style={{marginRight: '0px'}}>
          <MDBModalFooter>
            <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={() => {toggleSendEmail()}}>Cancelar</Button>
          </MDBModalFooter>
          <MDBModalFooter>
          {/* <BlobProvider document={<PDFFile modelo={item}/>}>
            {({ blob, url, loading, error }) => {
              return( */}
                <Button variant="contained" 
                  disabled={anexos.length > 0 ? (false):(true)}
                  onClick={() => {
                    sendEmail()
                  }} >
                    Enviar Email
                </Button>
              {/* ) */}
            {/* }} */}
          {/* </BlobProvider> */}
          </MDBModalFooter>
        </MDBRow>
      </MDBModal>
      
      {/* Modal de verificação do delete do email*/}
      {modalDeleteEmail ? (
        <MDBModal
          isOpen={modalDeleteEmail}
          toggle={toggleDeleteEmail}
          disableBackdrop
        >
          <MDBRow center>
            <MDBModalHeader>
              <span className="title-span" >
                ATENÇÃO!
              </span>
            </MDBModalHeader>
          </MDBRow>

          <MDBModalBody>
            <MDBRow center>
                <span style={{color:'black', fontWeight: 'bolder'}}>
                  Você deseja excluir esse email?
                </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                  <span>
                    Email: {emailForDel}
                  </span>
              </MDBRow>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleDeleteEmail}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'red'}} onClick={() => {handleDelEmail(emailForDel)}}>Apagar</Button>
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>             

      ):(null)}

    <MDBDataTableV5 
      order={['numeroPedido', 'desc']}
      responsive
      hover
      sortable
      entriesOptions={[5, 10, 15]}
      entries={10}
      pagesAmount={4}
      data={{ columns: columns, rows: rows }}
      pagingTop
      searchTop
      searchBottom={false}
      barReverse
      fixed
    />
    </>
    
  );
}