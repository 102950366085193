import axiosInstance from '../auth/AuthUserService.js'

export default class FornecedoresService {

  getAll() {
    const url = '/fornecedores/listar';
    return axiosInstance.get(url);
  }

  get(id) {
    const url = `/fornecedores/${id}`;
    return axiosInstance.get(url);
  }

  create(data) {
    const url = '/fornecedores/adicionar';
    return axiosInstance.post(url, data);
  }

  update(id, data) {
    const url = `/fornecedores/atualizar/${id}`;
    return axiosInstance.put(url, data);
  }

  delete(id) {
    const url = `/fornecedores/excluir/${id}`;
    return axiosInstance.delete(url);
  }

}